import React from "react";
import { useLoggingContext } from "../../contexts/LoggingContext";
import { Message } from "semantic-ui-react";

export const LoggingPlaceholder: React.FC = () => {
    const loggingContext = useLoggingContext();
    return (
        <>
            {loggingContext.errors.map((error, index) => (
                <Message key={index} error={true} content={error} visible={true} onDismiss={() => loggingContext.removeError(index)} />
            ))}
        </>
    );
};
