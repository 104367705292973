import * as React from "react";
import { Divider, Icon } from "semantic-ui-react";

import styles from "./css/BottomButtonBar.scss";

const ButtonDivider: React.FC = () => (
    <Icon />
);

export const BottomButtonBar: React.FC & { Divider: typeof ButtonDivider } = (props) => (
    <div className={styles.component}>
        <Divider />
        {props.children}
    </div>
);
BottomButtonBar.Divider = ButtonDivider;

export default BottomButtonBar;
