import * as React from "react";
import constate from "constate";
import { useHistory } from "react-router-dom";
import config from "./config";
import { usePromise } from "@/utils";
import Auth0AuthenticationClient from "./Auth0AuthenticationClient";
import AccessTokenUtility from "./AccessTokenUtility";
import { User } from "./IAuthenticationClient";

type AuthenticationContext = {
    isLoading: boolean;
    isVerifying: boolean;
    isAuthenticated: boolean;
    signOut: () => void;
    signIn: () => void;
    token: string | null;
    user: User | null,
};

const createAuthenticationClient = () => new Auth0AuthenticationClient({
    domain: config.domain,
    client_id: config.clientId,
    redirect_uri: window.location.origin,
    legacySameSiteCookie: false,
});

const useAuthentication = (): AuthenticationContext => {
    const history = useHistory();
    const client = React.useMemo(createAuthenticationClient, []);

    // Initialize the authentication client
    const [initializeStatus, initialize] = usePromise(client.initialize, {
        onSuccessful: (result) => {
            if (result.isAuthenticated) {
                AccessTokenUtility.setToken(result.token);
            }
        },
    });
    React.useEffect(() => {
        // Initialize the login procedure. And redirect to a new location after a fresh sign in has completed.
        initialize(history.push);
    }, []);

    const isLoading = initializeStatus.isRunning || initializeStatus.neverRun;
    const isVerifying = (initializeStatus.isRunning || initializeStatus.neverRun) && client.isResumingSession;
    const isAuthenticated = initializeStatus.isSuccess && initializeStatus.result.isAuthenticated;
    const user = initializeStatus.isSuccess && initializeStatus.result.isAuthenticated && initializeStatus.result.user || null;

    return {
        isLoading,
        isVerifying,
        isAuthenticated,
        user,
        signOut: () => {
            if (initializeStatus.isCompleted) {
                AccessTokenUtility.setToken(null);
                client.signOut();
            }
        },
        signIn: () => {
            if (!isLoading && !isAuthenticated && initializeStatus.isSuccess) {
                client.openSignIn();
            }
        },
        token: initializeStatus.isSuccess ? initializeStatus.result.token : null,
    };
};

export const [AuthenticationProvider, useAuthenticationContext] = constate(useAuthentication);
