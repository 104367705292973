import React from "react";
import { LoggingPlaceholder } from "@/components/common";
import { PageHeader } from "@/components/navigation";
import { ContentPage } from "./ContentPage";
import { WelcomePage } from "./WelcomePage";

import styles from "./css/layout.scss";

export const Layout: React.FC = () => (
    <>
        <PageHeader />
        <div className={styles.content}>
            <LoggingPlaceholder />
            <ContentPage>
                <WelcomePage />
            </ContentPage>
        </div>
    </>
);
