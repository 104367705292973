import React from "react";
import { AuthenticationCondition } from "@/auth";
import { AccountDropdown } from "./AccountDropdown";
import { AccountLocked } from "./AccountLocked";

import styles from "./css/pageHeader.scss";

export const PageHeader: React.FC = () => (
    <nav className={styles.component}>
        <div className="top"></div>
        <div className="center">
            <span className="logo">
                <img height="60" src="/images/logo.png" />
            </span>
            <span className="button">
                <AuthenticationCondition authenticated={true}>
                    <AccountDropdown />
                </AuthenticationCondition>
                <AuthenticationCondition authenticated={false}>
                    <AccountLocked />
                </AuthenticationCondition>
            </span>
        </div>
        <div className="bottom"></div>
    </nav>
);
