import React from "react";
import { AuthenticationCondition, useAuthenticationContext } from "@/auth";
import { LoginFailedPage } from "./LoginFailedPage";

export const ContentPage: React.FC = (props) => {
    const { isAuthenticated, isLoading, signIn } = useAuthenticationContext();

    const isAuth0Callback = React.useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        return params.has("iss");
    }, []);

    // Redirect to the login page when not signed in properly.
    React.useEffect(() => {
        if (!isAuth0Callback && !isLoading && !isAuthenticated) {
            signIn();
        }
    }, [isAuth0Callback, isLoading, isAuthenticated])

    return (
        <>
            <AuthenticationCondition authenticated={false}>
                {isAuth0Callback && <LoginFailedPage />}
            </AuthenticationCondition>
            <AuthenticationCondition authenticated={true}>
                {props.children}
            </AuthenticationCondition>
        </>
    );
};
