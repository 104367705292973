import * as React from "react";
import { useLoggingContext } from "@/contexts";
import { Icon, Message } from "semantic-ui-react";

import styles from "./css/ApplicationLoader.scss";

export const ApplicationLoader: React.FC = () => {
    const loggingContext = useLoggingContext();

    return (
        <div className={styles.component}>
            <div className={styles.widget}>
                <p><img src="/images/logo.png" /></p>
                {loggingContext.errors.length === 0 && (
                    <div className={styles.container}>
                        <div className={styles.loader}>
                            <div className={styles.dot1}></div>
                            <div className={styles.dot2}></div>
                            <div className={styles.dot3}></div>
                        </div>
                    </div>
                ) || (
                    <Message error={true}><Icon name="times circle" />
                        There was an problem while signing you in.<br />
                        Please try to refresh the page or contact your Keeyns administrator if the problem remains.
                    </Message>
                )}
            </div>
        </div>
    );
};