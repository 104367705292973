import React from "react";
import { Button, Checkbox, Form, Icon } from "semantic-ui-react";
import { useLoadingContext } from "../contexts";

export const LoginFailedPage: React.FC = () => {
    const [isLoginEnabled, setIsLoginEnabled] = React.useState(false);
    const loadingContext = useLoadingContext();
    React.useEffect(() => loadingContext.setIsLoading(false));

    return (
        <div>
            <h1>Login prevented</h1>
            <Icon name="shield" size="huge" style={{ float: "left" }} color="grey" />
            <div style={{ marginLeft: "80px" }}>
                <p>
                    To provide the best security, Keeyns is using the latest security measures to prevent unauthorized access to your company&apos;s documents.<br />
                    One of them is that the login screen uses unique identifiers that can only be used once.
                </p>
                <p>
                    The reason that you are viewing this page is that we have determined a security violation.<br />
                    High likely the reason why you get this is that you tried to open Keeyns through your bookmarks.
                </p>
                <p>
                    In case you did see this error page directly when opening your bookmark, follow the steps below to create a proper one:
                </p>
                <ol>
                    <li>Open Keeyns using the web address you got in the welcome email. It looks simular to <b>https://company.keeyns.com</b>.</li>
                    <li>Once logged in, remove the incorrect bookmark and create a new one.</li>
                </ol>
                <p>
                    As of now you can also go to <b>login.keeyns.com</b> using the button below and open Keeyns from there.<br />
                    In case you have access to multiple portals, you&apos;re able to select the portal to open.
                </p>
                <Form style={{marginTop: "30px"}}>
                    <Form.Field>
                        <Checkbox checked={isLoginEnabled} onChange={(e, d) => d.checked != null && setIsLoginEnabled(d.checked)} label="I confirm that I have read the above and performed appropriate actions." />
                    </Form.Field>
                    <Button primary={true} disabled={!isLoginEnabled} onClick={() => window.location.href = "/"}>
                        Continue to login.keeyns.com
                    </Button>
                </Form>
            </div>
        </div>
    );
};
