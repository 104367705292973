import React from "react";
import { Card, Icon, Image, Input, Message } from "semantic-ui-react";
import { useLoadingContext } from "../contexts";
import { useAthenaApi } from "../services";
import PortalDto from "../services/entities/PortalDto";
import { useChangeTimeout, usePromise } from "../utils";

const PortalList: React.FC<{ portals: PortalDto[] }> = (props) => {
    if (props.portals.length === 0) {
        return (
            <Message info={true}><Icon name="info" />No portals found</Message>
        );
    }
    return (
        <Card.Group style={{ marginTop: "0" }}>
            {props.portals.map((portal) => (
                <Card key={portal.guid} onClick={() => { window.location.href = portal.url }}>
                    <Card.Content>
                        <Image floated="right" size="mini" src={portal.imageUrl ?? "/portal_32x32.png"} />
                        <Card.Header>{portal.displayName}</Card.Header>
                        <Card.Meta>
                            {portal.url.replace(/^.*?\/\/(.+?)(?:\/|$)/, "$1")}
                        </Card.Meta>
                    </Card.Content>
                </Card>
            ))}
        </Card.Group>
    );
};

export const WelcomePage: React.FC = () => {
    const [filterConcept, setFilterConcept] = React.useState("");
    const [filter, setFilter] = React.useState("");

    const loadingContext = useLoadingContext();

    const api = useAthenaApi();
    const [listPortalsStatus, listPortals] = usePromise(api.portals.list, { onSuccessful: (result) => result.sort((a, b) => a.displayName.localeCompare(b.displayName)) });
    React.useEffect(listPortals, []);

    const portals = React.useMemo(() => {
        if (!listPortalsStatus.isSuccess) {
            return [];
        }

        // Automatically open the first item from the list
        if (listPortalsStatus.result.length === 1) {
            window.location.replace(listPortalsStatus.result[0].url);
            return [];
        }

        if (!filter) {
            return listPortalsStatus.result;
        }
        return listPortalsStatus.result.filter((portal) => portal.displayName.toLowerCase().includes(filter) || portal.url.toLowerCase().includes(filter));
    }, [filter, listPortalsStatus]);

    React.useEffect(() => {
        if (listPortalsStatus.isSuccess && listPortalsStatus.result.length !== 1) {
            loadingContext.setIsLoading(false);
        }
    }, [listPortalsStatus]);

    useChangeTimeout(() => {
        setFilter(filterConcept.toLowerCase());
    }, [filterConcept]);

    const onSearchChanged = (text: string) => {
        setFilterConcept(text);
    };
    const onEnterPressed = () => {
        if (filter && portals.length > 0) {
            window.location.href = portals[0].url
        }
    };

    return (
        <div>
            <h1>Welcome to Keeyns</h1>
            {listPortalsStatus.isRunning || listPortalsStatus.neverRun && (
                <div>Loading your portals...</div>
            ) || (
                <>
                    <p>
                        Please select the portal you would like to open from the list below.
                    </p>
                    <Input placeholder="Search..." icon="search" autoFocus={true} value={filterConcept}
                        onChange={(e, d) => onSearchChanged(d.value)}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.charCode === 13 && onEnterPressed()} />
                    {listPortalsStatus.isSuccess && (
                        <PortalList portals={portals} />
                    )}
                </>
            )}
        </div>
    );
};
