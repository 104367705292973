import React from "react";
import { Dropdown, Confirm, Button, Icon } from "semantic-ui-react";
import { useAuthenticationContext } from "@/auth";

import styles from "./css/accountDropdown.scss";

export const AccountDropdown: React.FC = () => {
    const [isSignoutModalVisible, setIsSignoutModalVisible] = React.useState(false);
    const [isSignoutActive, setIsSignoutActive] = React.useState(false);

    const { signOut, user } = useAuthenticationContext();

    const onSignoutClicked = () => setIsSignoutModalVisible(true);
    const onSignoutConfirm = () => signOut();

    return (
        <span className={styles.component}>
            <Dropdown floating={true} icon={null} trigger={
                <Icon name="user" color="grey" circular={true} size="large" inverted={true}>
                    <img src={user?.pictureUrl} />
                </Icon>
            }>
                <Dropdown.Menu direction="left">
                    <Dropdown.Header content={user?.fullName} />
                    <Dropdown.Divider />
                    <Dropdown.Item icon="sign out" content="Sign out" onClick={onSignoutClicked} />
                </Dropdown.Menu>
            </Dropdown>
            <Confirm
                open={isSignoutModalVisible}
                header="Confirm sign out"
                content="Are you sure you want to sign out?"
                cancelButton={<Button content="Cancel" disabled={isSignoutActive} onClick={() => setIsSignoutModalVisible(false)} />}
                confirmButton={<Button content="Confirm" disabled={isSignoutActive} loading={isSignoutActive} onClick={() => { setIsSignoutActive(true); onSignoutConfirm(); }} />}
            />
        </span>
    );
}
